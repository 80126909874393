import React from 'react'
import { useWebContext } from './../../contexts/WebContext';
import './style.css'

const Alert = () => {
    const { alert } = useWebContext()
    return alert.show && (
        <div className={'alert alert-' + alert.type}>
            {alert.message}
        </div>
    )
}

export default Alert
