import React from "react";
import "./style.css";
const BlogCard = ({ title, image, alt, description, pdf, date, tags }) => {
  return (
    <a className="blog-card" href={pdf} target="_blank" rel="noreferrer">
      <div className="blog-info">
        <h2>{title}</h2>
        <p>{description}</p>
        <p className='link'>[Read More]</p>
        <div className='posted'>
          Posted - {date}
        </div>
        <div className="tags">
          Tags - {tags}
        </div>
      </div>
      <div className="blog-img">
        <img src={image} alt={alt} />
      </div>
    </a>
  );
};

export default BlogCard;
