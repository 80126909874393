/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import "./style.css";
import { gsap } from "gsap/all";
import data from "../../../../HomeContent.json";
import { Link } from "react-router-dom";
const SkewCards = () => {
  const skewCardRef = useRef();
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: skewCardRef.current,
        start: "topR bottom",
        end: "20% 30%",
        scrub: true,
        // pin: true,
      },
    });

    tl.from(skewCardRef.current, {
      y: "100",
      opacity: 0,
    });
  }, []);
  return (
    <section ref={skewCardRef} className="skew">
      <div className="image">
        <img src='/assets/gifs/buynow.gif' alt="skewImg" />
      </div>
      <div className="description">
        <h2>{data.heroHeading1} {data.heroHeading2}</h2>
        <h2>{data.heroHeading3}</h2>
        <p>{data.BuyPrompt}</p>
        <Link to="/buynow" className="btn">
          Buy Now
        </Link>
      </div>
    </section>
  );
};

export default SkewCards;
