import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Nav from "../components/Nav";
import { useWebContext } from "../contexts/WebContext";
import { FaCheckCircle } from "react-icons/fa";
import "../styles/dashboard.css";
import NewsLetter from "../components/Newsletter";
import Footer from "../components/Footer";
import { db } from "../firebaseConfig";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const Dashboard = () => {
  const { userData, purchased } = useWebContext();
  const [currentPrice, setCurrentPrice] = useState(0);
  useEffect(() => {
    db.collection("data")
      .doc("price")
      .onSnapshot((doc) => {
        setCurrentPrice(doc.data().amount);
      });
  }, []);
  return (
    <section>
      <Nav />
      {userData && (
        <div className="dashboard">
          <div className="details">
            <h2>{userData && userData.name}'s Dashboard</h2>
            <p>{userData && userData.email}</p>
          </div>

          <div className="dashboard-card">
            {!purchased ? (
              <div className="perks">
                <div className="each-perk">
                  <FaCheckCircle className="check" />
                  <p>Lifetime Access</p>
                </div>
                <div className="each-perk">
                  <FaCheckCircle className="check" />
                  <p>Hand designed cards</p>
                </div>
                <div className="each-perk">
                  <FaCheckCircle className="check" />
                  <p>500+ digital cards</p>
                </div>
                <div className="each-perk">
                  <FaCheckCircle className="check" />
                  <p>Access to newly added cards</p>
                </div>
              </div>
            ) : (
              <div className="skewCard">
                <img src="/assets/gifs/buynow.gif" alt="skewImg" />
              </div>
            )}
            <div className="cta">
              <h2>
                Machine <br /> Learning <br /> Flashcards
              </h2>
              {!purchased && <p>Price: ₹{currentPrice} </p>}
              {!purchased && (
                <Link to="/buynow">
                  <button>Buy Now</button>
                </Link>
              )}
              {purchased && (
                <p>
                  <FaCheckCircle
                    className="check"
                    style={{ color: "#4caf50" }}
                  />
                  <p>
                    Congratulations! You are now all set to explore the Amazing
                    Machine Learning Flashcards. Enjoy!
                  </p>
                </p>
              )}
              {purchased && (
                <Link to="/gallery">
                  <button>View Cards</button>
                </Link>
              )}
            </div>
          </div>
          {!purchased && (
            <h3
              style={{
                textAlign: "center",
                marginTop: "80px",
                marginBottom: "30px",
              }}
            >
              Bonus Flashcards
            </h3>
          )}
          {!purchased && (
            <div className="carousel-container">
              <Carousel
                emulateTouch={true}
                labels={false}
                showArrows={true}
                showStatus={false}
                showThumbs={false}
                showIndicators={false}
                autoPlay={true}
                infiniteLoop={true}
                interval={3000}
              >
                <div>
                  <img src="/assets/images/dash/1.png" alt="" />
                </div>
                <div>
                  <img src="/assets/images/dash/2.png" alt="" />
                </div>
                <div>
                  <img src="/assets/images/dash/3.png" alt="" />
                </div>
                <div>
                  <img src="/assets/images/dash/4.png" alt="" />
                </div>
                <div>
                  <img src="/assets/images/dash/5.png" alt="" />
                </div>
                <div>
                  <img src="/assets/images/dash/6.png" alt="" />
                </div>
              </Carousel>
            </div>
          )}
        </div>
      )}
      <NewsLetter />
      <Footer />
    </section>
  );
};

export default Dashboard;
