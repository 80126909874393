/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./style.css";
import { db } from "../../firebaseConfig";
import { useWebContext } from "../../contexts/WebContext";
import validator from "validator";
import { isEmail } from "validator/lib/isEmail";
const NewsLetter = () => {
  const { setAlert } = useWebContext();
  const [email, setEmail] = useState("");
  const validEmail = validator.isEmail(email);
  const addEmail = () => {
    if (email === "" || validEmail === false) {
      setAlert({
        message: "Enter correct Email",
        show: true,
        type: "danger",
      });
      setTimeout(() => {
        setAlert({
          message: "",
          show: false,
          type: "",
        });
      }, 2000);
    } else {
      db.collection("newsletter")
        .where("email", "==", email)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            db.collection("newsletter").add({
              email: email,
            });
            setEmail("");
            setAlert({
              message: "Email added successfully",
              show: true,
              type: "success",
            });
            setTimeout(() => {
              setAlert({
                message: "",
                show: false,
                type: "",
              });
            }, 2000);
          } else {
            setAlert({
              message: "Email already exists",
              show: true,
              type: "danger",
            });
            setTimeout(() => {
              setAlert({
                message: "",
                show: false,
                type: "",
              });
            }, 2000);
          }
        });
    }
  };

  return (
    <section className="newsletter">
      <h2 className="news-heading">Subscribe to my newsletter</h2>
      <div className="input-group">
        <input
          type="email"
          placeholder="Email ID"
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="btn" onClick={addEmail}>
          Discover
        </div>
      </div>
    </section>
  );
};

export default NewsLetter;
