import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import dotenv from "dotenv";
dotenv.config();
const firebaseConfig = {
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // measurementIf: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  apiKey: "AIzaSyC7gYgRvekKsdJS3x61vDaRmT2tUaVukms",
  authDomain: "macrogoogol-ml-flashcards.firebaseapp.com",
  projectId: "macrogoogol-ml-flashcards",
  storageBucket: "macrogoogol-ml-flashcards.appspot.com",
  messagingSenderId: "723823432066",
  appId: "1:723823432066:web:00e6d6bb5d45582137ff9b",
  measurementId: "G-QYQKYH1S5L",
};
const app = firebase.initializeApp(firebaseConfig);
export const db = app.firestore();
export const auth = app.auth();
export default app;
