import React from "react";
import intro from "./Macro_Blue.mp4";
import Poster from "./videoPoster.png";
import "./style.css";
const Video = () => {
  return (
    <section className="video-holder">
      <div className="video-container">
        <video poster={Poster} src={intro} controls></video>
      </div>
    </section>
  );
};

export default Video;
