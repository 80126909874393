import { AnimatePresence, motion } from 'framer-motion';
import React, { useRef } from 'react'
import { CgSpinner } from 'react-icons/cg';
import { IoChevronBack } from 'react-icons/io5';
import { Redirect, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useWebContext } from '../contexts/WebContext';

const ForgotPassword = () => {
    const emailRef = useRef();
    const { sendPasswordResetEmail, currentUser, loading, setLoading, setAlert } =
        useWebContext();
    const history = useHistory();
    const formSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        sendPasswordResetEmail(emailRef.current.value)
            .then(() => {
                setLoading(false);
                setAlert({
                    show: true,
                    message: "email sent",
                    type: "success",
                });
                setTimeout(() => {
                    setAlert({
                        show: false,
                        message: "",
                        type: "",
                    });
                }, 2000);
                history.push("/signin");
            })
            .catch((err) => {
                setAlert({
                    show: true,
                    message: err.message,
                    type: "danger",
                });
                setTimeout(() => {
                    setAlert({
                        show: false,
                        message: "",
                        type: "",
                    });
                }, 4000);
                setLoading(false);
            });
    };
    return currentUser ? (
        <Redirect to="/dashboard" />
    ) : (
        <AnimatePresence>
            <motion.div className="form-container">
                <motion.form
                    layout
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    className="form"
                    onSubmit={formSubmitHandler}
                >
                    <h2>Reset Password</h2>
                    <div className="form-details">
                        <input type="email" ref={emailRef} placeholder="Email" />
                        {loading ? (
                            <CgSpinner />
                        ) : (
                            <button className="btn" type="submit">
                                Send Reset Link
                            </button>
                        )}
                        <br />
                        <Link to="/signin">
                            <span className="new">Log in? &gt;</span>
                        </Link>
                        <Link to="/">
                            <button className="btn" type="submit">
                                <IoChevronBack /> Go Back
                            </button>
                        </Link>
                    </div>
                </motion.form>
            </motion.div>
        </AnimatePresence>
    );
}

export default ForgotPassword
