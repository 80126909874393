import React, { useContext, useEffect, useState } from "react";
import Alert from "../components/Alert";
import { auth, db } from "../firebaseConfig";
import Loader from "./../components/Loader/index";

const WebContext = React.createContext();

export const useWebContext = () => {
  return useContext(WebContext);
};

export const WebProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "",
  });
  const [currentUser, setCurrentUser] = useState();
  const [purchased, setPurchased] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState();
  useEffect(() => {
    setLoading(true);
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setTimeout(() => {
        currentUser &&
          db
            .collection("users")
            .doc(currentUser.uid)
            .onSnapshot((doc) => {
              setUserData(doc.data());
              setPurchased(doc.data().purchased || doc.data().adminApproved); //TODO:Add one more check for admin approved
              setLoading(false);
            });
      }, 2000);
      !currentUser && setLoading(false);
    });
  }, [currentUser]);
  const sendPasswordResetEmail = (email) => {
    return auth.sendPasswordResetEmail(email);
  };
  const signIn = (email, pass) => {
    return auth.signInWithEmailAndPassword(email, pass);
  };
  const signUp = (email, pass) => {
    return auth.createUserWithEmailAndPassword(email, pass);
  };
  const logOut = () => {
    return auth.signOut();
  };

  const value = {
    currentUser,
    setCurrentUser,
    signIn,
    logOut,
    sendPasswordResetEmail,
    signUp,
    purchased,
    setPurchased,
    userData,
    setUserData,
    loading,
    setLoading,
    alert,
    setAlert,
  };
  return (
    <WebContext.Provider value={value}>
      {!loading ? (
        <div>
          {children}
          <Alert />
        </div>
      ) : (
        <Loader />
      )}
    </WebContext.Provider>
  );
};
