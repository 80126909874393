/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Loader from "../components/Loader/index";
import Nav from "../components/Nav";
import NewsLetter from "../components/Newsletter";
import GalleryCard from "../components/Screens/gallery/Card";
import { db } from "../firebaseConfig";
import "../styles/gallery.css";
const Gallery = () => {
  const [loading, setLoading] = useState(false);
  const [zipFile, setZipFile] = useState(null);
  const [cards, setCards] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [filteredCard, setFilteredCards] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    setLoading(true);
    db.collection('data').doc('zipFile').onSnapshot(doc => {
      setZipFile(doc.data().fileURL);
    })
    db.collection("cards").onSnapshot((snapshot) => {
      setCards(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          front: doc.data().cardFrontImageUrl,
          back: doc.data().cardBackImageUrl,
          title: doc.data().cardTitle,
          category: doc.data().cardCategory,
          pdf: doc.data().cardPdfUrl,
        }))
      );
      // map unique categoies
      const uniqueCategories = snapshot.docs.map((doc) => doc.data().cardCategory);
      const uniqueCategoriesSet = new Set(uniqueCategories);
      const uniqueCategoriesArray = Array.from(uniqueCategoriesSet);
      setCategories(uniqueCategoriesArray);
      setLoading(false);
    });
  }, [setLoading]);
  const onSearch = () => {
    setFilteredCards(
      cards.filter(
        (card) =>
          card.title.toLowerCase().includes(search.toLowerCase())
      )
    );
  };
  return (
    <section>
      <Nav />
      {loading ? (
        <Loader />
      ) : cards.length === 0 ? (
        <section className="gallery">
          <h2>No cards found. Please check back later</h2>
        </section>
      ) : (
        <section className="gallery">
          <div className="downloadAllCards">
            <a className='downloadAllBtn' href={zipFile}>Download All Cards</a>
          </div>
          <input
            className="searchCard"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
            onKeyUp={onSearch}
          />
          <div className="categories">
            <div className="category-list">
              <button
                className={`${selectedCategory}`}
                onClick={() => setSelectedCategory("All")}
              >
                All
              </button>
              {categories.map((category, index) => (
                <button key={index} onClick={() => setSelectedCategory(category)}>
                  {category}
                </button>
              ))}
            </div>
          </div>
          <div className="card-list">
            {search === "" ? (
              cards.map(({ front, back, id, title, category, pdf }) => {
                if (selectedCategory === "All") {
                  return (
                    <GalleryCard
                      front={front}
                      back={back}
                      title={title}
                      key={id}
                      pdf={pdf}
                      category={category}
                    />
                  );
                } else if (category === selectedCategory) {
                  return (
                    <GalleryCard
                      front={front}
                      back={back}
                      title={title}
                      key={id}
                      category={category}
                    />
                  );
                }
              })
            ) : filteredCard.length > 0 ? (
              filteredCard.map(({ front, back, id, title, category }) => {
                return (
                  <GalleryCard
                    front={front}
                    back={back}
                    title={title}
                    key={id}
                    category={category}
                  />
                );
              })
            ) : (
              <h2>No results found</h2>
            )}
          </div>
        </section>
      )}
      <NewsLetter />
      <Footer />
    </section>
  );
};

export default Gallery;
