/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useWebContext } from "./../contexts/WebContext";
import "../styles/styles.css";
import { IoChevronBack } from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion";
import { CgSpinner } from "react-icons/cg";
const SignIn = () => {
  const emailRef = useRef();
  const passRef = useRef();
  const { signIn, currentUser, loading, setLoading, setAlert } =
    useWebContext();
  const history = useHistory();
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    signIn(emailRef.current.value, passRef.current.value)
      .then(() => {
        setLoading(false);
        setAlert({
          show: true,
          message: "logged in",
          type: "success",
        });
        setTimeout(() => {
          setAlert({
            show: false,
            message: "",
            type: "",
          });
        }, 2000);
        history.push("/dashboard");
      })
      .catch((err) => {
        setAlert({
          show: true,
          message: err.message,
          type: "danger",
        });
        setTimeout(() => {
          setAlert({
            show: false,
            message: "",
            type: "",
          });
        }, 4000);
        setLoading(false);
      });
  };
  return currentUser ? (
    <Redirect to="/dashboard" />
  ) : (
    <AnimatePresence>
      <motion.div className="form-container">
        <motion.form
          layout
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="form"
          onSubmit={formSubmitHandler}
        >
          <h2>Sign In</h2>
          <div className="form-details">
            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                ref={emailRef}
                placeholder="Email"
                id="email"
              />
            </div>
            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                ref={passRef}
                placeholder="Password"
                id="password"
              />
              <Link to="/forgot-password">
                <span className="new">Forgot Password?</span>
              </Link>
            </div>
            {loading ? (
              <CgSpinner />
            ) : (
              <button className="btn" type="submit">
                Sign In
              </button>
            )}

            <p className="create-tag">
              Don't have an account?&nbsp;
              <Link to="/signup">
                <span className="new">Sign Up</span>
              </Link>
            </p>

            <Link to="/">
              <p className="back-link">
                <IoChevronBack /> Go Back
              </p>
            </Link>
          </div>
        </motion.form>
      </motion.div>
    </AnimatePresence>
  );
};

export default SignIn;
