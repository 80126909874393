import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useWebContext } from "./../contexts/WebContext";

export default function BuyNowRoute({ component: Component, ...rest }) {
  const { currentUser, purchased } = useWebContext();
  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? (
          !purchased ? (
            <Component {...props} />
          ) : (
            <Redirect to="/dashboard" />
          )
        ) : (
          <Redirect to="/signup" />
        );
      }}
    />
  );
}
