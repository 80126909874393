import React, { useEffect, useRef, useState } from "react";
import Nav from "../components/Nav";
import "../styles/blog.css";
import NewsLetter from "../components/Newsletter";
import Footer from "../components/Footer";
// import blogs from "../BlogData.json";
import BlogCard from "../components/Screens/Home/Blog/BlogCard";
import { db } from "../firebaseConfig";
import Typed from "typed.js";
const Blog = () => {
  const el = useRef(null);
  const typed = useRef(null);
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    db.collection("blogs").onSnapshot((snapshot) => {
      setBlogs(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          title: doc.data().blogTitle,
          description: doc.data().blogDesc,
          image: doc.data().imageUrl,
          pdf: doc.data().pdfUrl,
          date: doc.data().createdAt.toDate().toDateString(),
          tags: doc.data().tags,
        }))
      );
    });
    const options = {
      strings: [
        "Technology",
        "Machine Learning",
        "Artificial Intelligence",
        "Space & the Cosmos",
      ],
      typeSpeed: 100,
      backSpeed: 50,
      loop: true,
      showCursor: true,
      cursorChar: "|",
    };
    typed.current = new Typed(el.current, options);

    return () => {
      typed.current.destroy();
    };
  }, []);
  return (
    <section>
      <Nav />
      <div className="blog">
        <h2
          style={{
            textAlign: "center",
            fontSize: "2rem",
          }}
        >
          I write about
          <br />
          <span
            className="typed"
            style={{
              color: "var(--a-blue)",
            }}
            ref={el}
          ></span>
        </h2>
        <div className="blog-list">
          {blogs.map(({ title, image, pdf, description, date, tags }) => (
            <BlogCard
              title={title}
              image={image}
              description={description}
              alt={title}
              pdf={pdf}
              date={date}
              tags={tags}
            />
          ))}
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </section>
  );
};

export default Blog;
