import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Card = ({ title, description, imagefront, alt, reverse, imageback }) => {
  const imgRef = useRef();
  const cardRef = useRef();
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: cardRef.current,
        start: "top bottom",
        end: "30% 30%",
        scrub: true,
        // markers: true,
        // pin: true,
      },
    });

    tl.from(cardRef.current, {
      y: "100",
      opacity: 0,
      duration: 1,
    });
  }, []);

  return (
    <div ref={cardRef} className={!reverse ? "card" : "card card-reverse"}>
      <div className="description">
        <div className="title">
          <h3>{title}</h3>
        </div>
        <p>{description}</p>
      </div>
      <div className="row-image">
        <img
          className="card-side front"
          src={imagefront}
          alt={alt}
          ref={imgRef}
        />
        <img
          className="card-side back"
          src={imageback}
          alt={alt}
          ref={imgRef}
        />
      </div>
    </div>
  );
};

export default Card;
