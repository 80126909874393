/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useWebContext } from "./../contexts/WebContext";
import "../styles/styles.css";
import { db } from "../firebaseConfig";
import { IoChevronBack } from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion";
import { CgSpinner } from "react-icons/cg";
const SignUp = () => {
  const [name, setName] = useState("");
  const passRef = useRef();
  const { signUp, currentUser, setLoading, loading, setAlert } =
    useWebContext();
  const [email, setEmail] = useState("");
  const history = useHistory();
  const signupHandler = async (e) => {
    e.preventDefault();

    setLoading(true);
    signUp(email, passRef.current.value)
      .then((user) => {
        db.collection("users")
          .doc(user.user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              history.push("/buynow");
            } else {
              db.collection("users")
                .doc(user.user.uid)
                .set({
                  name: name,
                  email: email,
                  purchased: false,
                  adminApproved: false,
                  //TODO: add admin approved property
                })
                .then(() => {
                  setLoading(false);
                  setAlert({
                    show: true,
                    message: "logged in",
                    type: "success",
                  });
                  setTimeout(() => {
                    setAlert({
                      show: false,
                      message: "",
                      type: "",
                    });
                  }, 2000);
                  history.push("/signin");
                });
            }
          });
      })
      .catch((err) => {
        setAlert({
          show: true,
          message: err.message,
          type: "danger",
        });
        setTimeout(() => {
          setAlert({
            show: false,
            message: "",
            type: "",
          });
        }, 4000);
        setLoading(false);
      });
  };
  return currentUser ? (
    <Redirect to="/dashboard" />
  ) : (
    <AnimatePresence>
      <motion.div
        layout
        initial={{ y: 30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="form-container"
      >
        <motion.form onSubmit={signupHandler} className="form">
          <h2>Sign Up</h2>
          <div className="form-details">
            <div className="input-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </div>
            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>
            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input type="password" ref={passRef} placeholder="Password" />
            </div>
            {loading ? (
              <CgSpinner />
            ) : (
              <button className="btn" type="submit">
                Sign Up
              </button>
            )}
            <p className="create-tag">
              Already have an account?&nbsp;
              <Link to="/signin">
                <span className="new">Sign In</span>
              </Link>
            </p>

            <Link to="/">
              <p className="back-link">
                <IoChevronBack /> Go Back
              </p>
            </Link>
          </div>
        </motion.form>
      </motion.div>
    </AnimatePresence>
  );
};

export default SignUp;
