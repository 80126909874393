import React, { useState } from "react";

const GalleryCard = ({ front, back, category, title, pdf }) => {
  // console.log(category);
  const [flip, setFlip] = useState(false);
  return (
    <div className="flashcard">
      <div className="flashcard-image">
        <a href={flip ? back : front} target="_blank" rel="noreferrer">
          {
            flip ? (
              <img src={back} alt={title} />
            ) : (
              <img src={front} alt={title} />
            )
          }
        </a>
      </div>

      <p>{title}</p>
      {/* <p>{category}</p> */}
      <div className="btns">
        <button className="flip" onClick={() => setFlip(!flip)}>
          Flip
        </button>
        <button className="download">
          <a target='_blank'
            rel="noopener noreferrer"
            href={pdf}
          >Download</a>
        </button>
      </div>
    </div>
  );
};

export default GalleryCard;
