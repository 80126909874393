import React from "react";
import Nav from "../components/Nav";
import "../styles/404.css";
const PageNotFound = () => {
  return (
    <>
      <Nav />
      <section className="notfound">
        <div className="img-holder">
          <img
            src="https://image.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg"
            alt="404"
          />
        </div>
        <div id="info">
          <h2>This page could not be found</h2>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
