import React, { useEffect, useRef } from "react";
import FaqDisclosure from "./Faq";
import faqData from "./faqData.json";
import "./style.css";
import { AnimateSharedLayout } from "framer-motion";
import { gsap } from "gsap/all";
const Faq = () => {
  const faqContainer = useRef();
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: faqContainer.current,
        start: "top bottom",
        end: "80% 30%",
        scrub: true,
        // pin: true,
      },
    });

    tl.from(faqContainer.current, {
      y: "100",
      opacity: 0,
    });
  }, []);
  return (
    <section>
      <div ref={faqContainer} className="faqContainer">
        <h2>Got a doubt? Let’s take a Look</h2>
        <AnimateSharedLayout>
          {faqData.map((faq, index) => (
            <FaqDisclosure
              key={index}
              question={faq.title}
              answer={faq.description}
              icons={faq.icons}
            />
          ))}
        </AnimateSharedLayout>
      </div>
    </section>
  );
};

export default Faq;
