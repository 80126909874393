import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { useWebContext } from "../../../../contexts/WebContext";
import { db } from "../../../../firebaseConfig";
import { FaCheckCircle } from "react-icons/fa";

const Buynow = () => {
  const { userData, purchased } = useWebContext();
  const [currentPrice, setCurrentPrice] = useState(0);
  useEffect(() => {
    db.collection("data")
      .doc("price")
      .onSnapshot((doc) => {
        setCurrentPrice(doc.data().amount);
      });
  }, []);
  return (
    <div className="dashboard">
      <div className="dashboard-card">
        <div className="perks">
          <div className="each-perk">
            <FaCheckCircle className="check" />
            <p>Lifetime Access</p>
          </div>
          <div className="each-perk">
            <FaCheckCircle className="check" />
            <p>Hand designed cards</p>
          </div>
          <div className="each-perk">
            <FaCheckCircle className="check" />
            <p>500+ digital cards</p>
          </div>
          <div className="each-perk">
            <FaCheckCircle className="check" />
            <p>Access to newly added cards</p>
          </div>
        </div>

        <div className="cta">
          <h2>
            Machine <br /> Learning <br /> Flashcards
          </h2>
          {!purchased && <p>Price: ₹{currentPrice} </p>}
          {!purchased && (
            <Link to="/buynow">
              <button>Buy Now</button>
            </Link>
          )}
          {purchased && (
            <p>
              <FaCheckCircle className="check" style={{ color: "#4caf50" }} />
              <p>
                You now have access to the Awesome Machine Learning Flashcards
              </p>
            </p>
          )}
          {purchased && (
            <Link to="/gallery">
              <button>View Cards</button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Buynow;
