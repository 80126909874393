/* eslint-disable jsx-a11y/anchor-is-valid */
import { gsap } from "gsap/all";
import React, { useEffect, useRef } from "react";
import "./style.css";
import data from "../../../../HomeContent.json";
const Info = () => {
  const infoRef = useRef();
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: infoRef.current,
        start: "top 80%",
        end: "20% 50%",
        scrub: true,
      },
    });

    tl.from(infoRef.current, {
      y: "100",
      opacity: 0,
    });
  }, []);
  return (
    <section ref={infoRef} className="info">
      <div className="info-header">
        <h3>{data.infoHeader}</h3>
        <p>{data.infoSubheading}</p>
      </div>
      <div className="info-details">
        <div className="detail">
          <img src={data.infoDetail1Gif} alt="gif1" />

          <h4>{data.infoDetail1Heading}</h4>
          <p>{data.infoDetail1Text}</p>
        </div>
        <div className="detail">
          <img src={data.infoDetail2Gif} alt="gif1" />

          <h4>{data.infoDetail2Heading}</h4>
          <p>{data.infoDetail2Text}</p>
        </div>
        <div className="detail">
          <img src={data.infoDetail3Gif} alt="gif1" />

          <h4>{data.infoDetail3Heading}</h4>
          <p>{data.infoDetail3Text}</p>
        </div>
      </div>
    </section>
  );
};

export default Info;
