import React, { useEffect, useRef, useState } from "react";
import { IoCaretUpCircleSharp } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";
import { gsap } from "gsap/all";
const FaqDisclosure = ({ question, answer, icons }) => {
  const [rotate, setRotate] = useState(false);
  const faqRef = useRef();
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: faqRef.current,
        start: "top bottom",
        end: "80% 30%",
        // markers: true,
      },
    });

    tl.from(faqRef.current, {
      y: "100",
      opacity: 0,
      duration: 1,
    });
  }, []);
  return (
    <motion.div ref={faqRef} >
      <motion.div layout className="disclosure-btn" >
        <motion.div layout className="btn-top" onClick={() => setRotate(!rotate)}>
          <motion.p layout>{question}</motion.p>
          <IoCaretUpCircleSharp
            className={`${rotate ? "down-icon " : "down-icon toggle"}`}
          />
        </motion.div>

        <AnimatePresence>
          {rotate && (
            <motion.div
              className="disclosure-panel"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              layout
            >
              <motion.p layout exit={{ opacity: 0 }}>
                {answer}
              </motion.p>
              {
                icons && (
                  <motion.div layout exit={{ opacity: 0 }} style={{
                    marginTop: "1rem",
                  }} className="icons">
                    <a target='_blank' rel='noreferrer' href={icons.linkedin.link}><img width='32px' style={{
                      marginRight: "1rem",
                    }} src={icons.linkedin.icon} alt="linkedin" /></a>
                    <a target='_blank' rel='noreferrer' href={icons.twitter.link}><img width='32px' src={icons.twitter.icon} alt="twitter" /></a>
                  </motion.div>
                )
              }

            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

export default FaqDisclosure;
