import React from "react";
import Card from "./Card";
import data from "./dataCards.json";

import "./style.css";
const RowCards = () => {
  return (
    <section className="rowCards" data-aos="fade-zoom-in">
      {data.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          description={card.description}
          alt={card.alt}
          imagefront={card.imagefront}
          imageback={card.imageback}
          reverse={card.reverse}
        />
      ))}
    </section>
  );
};

export default RowCards;
