/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { useWebContext } from "../../contexts/WebContext";

const Nav = () => {
  const { currentUser, purchased, setPurchased, logOut, setAlert, setLoading } =
    useWebContext();
  const [open, setOpen] = useState(false);
  const logOutHandler = () => {
    setLoading(true);
    logOut()
    setTimeout(() => {
      setPurchased(false);
      setLoading(false);
      setAlert({
        show: true,
        message: "You have been logged out",
        type: "success",
      });
      setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          type: "",
        });
      }, 2000);
    }, 1000);
  };
  return (
    <nav className="navbar">
      <Link to="/" id="logo" className='logo'>
        <img width="42px" src="/assets/icons/logoW.png" alt="macrogoogol" />
        &nbsp;
        <span>
          MACROGOOGOL
        </span>
      </Link>
      <div className={`${open ? "navLinks active" : "navLinks"}`}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/blog">Blogs</Link>
          </li>
          {purchased && (
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
          )}

          {currentUser && (
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
          )}
          {!purchased && (
            <li>
              <Link to="/buynow">
                <button className="btn">Buy Now</button>
              </Link>
            </li>
          )}
          {!currentUser ? (
            <li>
              <Link to="/signin">
                <button className="btn-outline">Sign In</button>
              </Link>
            </li>
          ) : (
            <li>
              <button className="btn-outline" onClick={logOutHandler}>
                LogOut
              </button>
            </li>
          )}
        </ul>
      </div>
      <div className="menu" onClick={() => setOpen(!open)}>
        <IoMenu className="hamburger" />
      </div>
    </nav>
  );
};

export default Nav;
