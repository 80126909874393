import { Switch, Route } from "react-router-dom";
import { WebProvider } from "./contexts/WebContext";
import AdminRoute from "./routes/AdminRoute";
import BuyNowRoute from "./routes/BuyNowRoute";
import PurchasedRoute from "./routes/PurchasedRoute";
import Blog from "./screens/Blog";
import Buynow from "./screens/Buynow";
import Dashboard from "./screens/Dashboard";
import ForgotPassword from "./screens/ForgotPassword";
import Gallery from "./screens/Gallery";
import Home from "./screens/Home";
import PageNotFound from "./screens/NotFound";
import SignIn from "./screens/SignIn";
import SignUp from "./screens/SignUp";
import ScrollToTop from "./ScrollToTop";
function App() {
  return (
    <>
      <WebProvider>
        <ScrollToTop>
          <Switch>
            <Route path="/signin" exact component={SignIn} />
            <Route path="/signup" exact component={SignUp} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/" exact component={Home} />
            <Route path="/blog" exact component={Blog} />
            <BuyNowRoute path="/buynow" exact component={Buynow} />
            <AdminRoute path="/dashboard" exact component={Dashboard} />
            <PurchasedRoute path="/gallery" exact component={Gallery} />
            <Route component={PageNotFound} path="/*" />
          </Switch>
        </ScrollToTop>
      </WebProvider>
    </>
  );
}

export default App;
