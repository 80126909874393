import React from "react";
import "./style.css";
import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { Power2 } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import data from "../../../../HomeContent.json";
import { Link } from "react-router-dom";
import logoImg from "./logo1.png";
const Hero = () => {
  gsap.registerPlugin(ScrollTrigger);
  const heroRef = useRef();
  const splash = useRef();
  const splashLogo = useRef();
  const heroDetailRef = useRef();
  const scrollMouse = useRef();
  const socialRef = useRef();
  useEffect(() => {
    const tl = gsap.timeline(
      {
        scrollTrigger: {
          trigger: heroRef.current,
          start: "top top",
          end: "bottom 10%",
          scrub: true,
          // markers: true,
          pin: true,
        },
      },
      { Power2 }
    );
    const tl2 = gsap.timeline();
    tl2
      .to(splashLogo.current, {
        duration: 1.5,
        opacity: 1,
      })
      .to(splash.current, {
        y: "-100%",
        opacity: 0,
        duration: 1.5,
      });

    tl.to(heroDetailRef.current, {
      opacity: 0,
      duration: 1,
      y: -100,
    })
      .to(scrollMouse.current, {
        duration: 1,
        opacity: 0,
        y: -100,
      })
      .to(socialRef.current, {
        duration: 1,
        opacity: 0,
        y: -100,
      })
      .to(heroRef.current, {
        rotateZ: "180deg",
        scale: 0,
        opacity: 0,
        duration: 3,
        ease: Power2,
      });
  }, []);
  return (
    <section className="hero" ref={heroRef}>
      <div className="cover" ref={splash}>
        <div className="holder" ref={splashLogo}>
          <img src={logoImg} alt="" />
          <h1>Macrogoogol</h1>
        </div>
      </div>
      <div ref={heroDetailRef} className="hero-details">
        <h1>{data.heroHeading1}</h1>
        <h1>{data.heroHeading2}</h1>
        <h1>{data.heroHeading3}</h1>
        <br />
        <Link to="/buynow" className="btn">
          Buy Now
        </Link>
      </div>
      {/* <div ref={socialRef} className="socials">
        <a
          href="https://www.linkedin.com/in/sankalp-arora-10bbbb9a/"
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn
        </a>
        <a
          href="https://twitter.com/I_sankalparora"
          target="_blank"
          rel="noreferrer"
        >
          Twitter
        </a>
      </div> */}
      <div ref={scrollMouse} className="mouse-container">
        <div className="mouse">
          <span className="scroll-down"></span>
        </div>
        <div
          style={{
            height: "10px",
          }}
        ></div>
        <span
          className="scroll-down-text"
          style={{
            color: "white",
          }}
        >
          Scroll Down
        </span>
      </div>
    </section>
  );
};

export default Hero;
