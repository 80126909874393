import React, { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useWebContext } from "../contexts/WebContext";
import { db } from "../firebaseConfig";
import "../styles/buynow.css";
import validator from "validator";
import Loader from "../components/Loader";
import axios from "axios";
import "react-phone-number-input/style.css";
import { IoChevronBack } from "react-icons/io5";
// import countryData from "../country.json";

const Buynow = () => {
  const { currentUser, setAlert, setLoading, userData } = useWebContext();
  const [spinner, setSpinner] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phNumber, setPhNumber] = useState("");
  const [currentPrice, setCurrentPrice] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    name: "India",
    dialCode: "91",
    flag: "🇮🇳",
  });
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  //function which calls an api to get country data
  const getCountryData = async () => {
    const response = await fetch(
      "https://api-bdc.net/data/countries?key=bdc_84658772d7d2477eb59001a85d674556"
    );
    const data = await response.json();
    const newData = data.map((country) => ({
      name: country.name,
      dialCode: country.callingCode,
      flag: country.countryFlagEmoji,
    }));
    setCountryData(newData);
  };

  const goBack = () => {
    history.push("/dashboard");
  };
  const openDropdown = () => {
    setIsOpen(true);
  };
  const closeDropdown = (country) => {
    setSelectedCountry(country);
    setIsOpen(false);
  };
  useEffect(() => {
    getCountryData();
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    setLoadingData(true);
    userData && userData.name && setName(userData.name);
    userData && userData.email && setEmail(userData.email);
    db.collection("data")
      .doc("price")
      .onSnapshot((doc) => {
        setCurrentPrice(doc.data().amount);
        setLoadingData(false);
      });
  }, [userData]);
  const checkout = (e) => {
    e.preventDefault();
    if (phNumber.length === 10 && validator.isInt(phNumber)) {
      setSpinner(true);
      axios({
        method: "post",
        url: "https://ill-tuna-spacesuit.cyclic.app/createOrder",
        data: {
          amount: currentPrice * 100,
          currency: "INR",
        },
      })
        .then((res) => {
          console.log(res.data);
          const options = {
            key: "rzp_live_nRs5XQizbp9EWW",
            amount: res.data.amount, // amount in the smallest currency unit
            currency: "INR",
            name: "Macrogoogol",
            description: "Machine Learning Flashcards",
            order_id: res.data.id,
            modal: {
              ondismiss: function () {
                setAlert({
                  type: "danger",
                  message: "Payment Cancelled",
                  show: true,
                });
                setTimeout(() => {
                  setAlert({
                    show: false,
                    type: "",
                    message: "",
                  });
                }, 3000);
                setSpinner(false);
                console.log("dismissed");
              },
              onclose: function () {
                setAlert({
                  type: "danger",
                  message: "Payment Cancelled",
                  show: true,
                });
                setTimeout(() => {
                  setAlert({
                    show: false,
                    type: "",
                    message: "",
                  });
                }, 3000);
                setSpinner(false);
                console.log("closed");
              },
            },
            handler: function (response) {
              console.log(response);
              const values = {
                orderId: response.razorpay_order_id,
                paymentId: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              };
              axios({
                method: "post",
                url: "https://ill-tuna-spacesuit.cyclic.app/verifyPayment",
                data: values,
              })
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status === "success") {
                    setSpinner(false);
                    setAlert({
                      show: true,
                      type: "success",
                      message: "Payment Successful",
                    });
                    setTimeout(() => {
                      setAlert({
                        show: false,
                        type: "",
                        message: "",
                      });
                    }, 3000);
                    db.collection("users")
                      .doc(currentUser.uid)
                      .update({
                        purchased: true,
                        amount: currentPrice,
                        dialCode: selectedCountry.dialCode,
                        country: selectedCountry.name,
                        phone: phNumber,
                        purchasedAt: new Date(),
                      })
                      .then(() => {
                        setLoading(true);
                        setTimeout(() => {
                          setLoading(false);
                          history.push("/dashboard");
                        }, 3000);
                      });
                  } else {
                    setSpinner(false);
                    setAlert({
                      show: true,
                      type: "danger",
                      message: "Payment Failed",
                    });
                    setTimeout(() => {
                      setAlert({
                        show: false,
                        type: "",
                        message: "",
                      });
                    }, 3000);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            },
            prefill: {
              name: name,
              email: email,
              contact: phNumber,
            },
          };
          const instance = new window.Razorpay(options);
          instance.open();
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
        });
    } else {
      setAlert({
        show: true,
        message: "Enter valid mobile number",
        type: "danger",
      });
      setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          type: "",
        });
      }, 2000);
      setSpinner(false);
    }
  };
  return loadingData ? (
    <Loader />
  ) : (
    <section className="buynow">
      <form className="form">
        <h2>Buy Now</h2>
        <div className="input-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            placeholder="Name"
            value={name}
            disabled
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            placeholder="Email"
            value={email}
            required
            disabled
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {/* <PhoneInput
          className="phNum"
          country="US"
          placeholder="Mobile Number"
          value={phNumber}
          required
          onChange={setPhNumber}
        /> */}

        <div className="input-group">
          <label htmlFor="country">Country Code</label>

          <div className="country">
            <div className="country-btn" onClick={openDropdown}>
              <span>{selectedCountry.flag}</span>
              <span>+{selectedCountry.dialCode}</span>
              <span> {selectedCountry.name}</span>
            </div>

            {isOpen && (
              <ul className="country-list">
                {countryData.map((country) => (
                  <li
                    className="country-item"
                    onClick={(e) => closeDropdown(country)}
                  >
                    <span>{country.flag}</span>
                    <span>+{country.dialCode}</span>
                    <span> {country.name}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="input-group">
          <label htmlFor="phNumber">Mobile Number</label>
          <input
            type="text"
            placeholder="Mobile Number"
            value={phNumber}
            required
            onChange={(e) => setPhNumber(e.target.value)}
          />
        </div>

        <button onClick={checkout}>Pay Now</button>
        <Link to="/">
          <p className="back-link">
            <IoChevronBack /> Go Back
          </p>
        </Link>
        {spinner && <CgSpinner className="spin" />}
      </form>
    </section>
  );
};

export default Buynow;
