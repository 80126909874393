import React from "react";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import Faq from "../components/Screens/Home/Faq";
import Hero from "../components/Screens/Home/Hero/Index";
import Info from "../components/Screens/Home/Info";
import NewsLetter from "../components/Newsletter";
import RowCards from "../components/Screens/Home/Rows/Index";
import SkewCards from "../components/Screens/Home/SkewCards/Index";
import Video from "../components/Screens/Home/Video";
import { gsap } from "gsap/all";
import ScrollTrigger from "gsap/ScrollTrigger";
import Buynow from "../components/Screens/Home/Buynow";

const Home = () => {
  gsap.registerPlugin(ScrollTrigger);

  return (
    <div>
      <Nav />
      <Hero />
      <Video />
      <RowCards />
      <SkewCards />
      <Info />
      <Buynow />
      <Faq />
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default Home;
