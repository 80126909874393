/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  IoCallOutline,
  IoLogoChrome,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoMailOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import "./style.css";
import { useWebContext } from "./../../contexts/WebContext";
const Footer = () => {
  const { purchased } = useWebContext();
  return (
    <section className="footer">
      <h2>Get in touch!</h2>
      {/* <div className="links">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/blog">
              Blogs
            </Link>
          </li>
          {purchased && (
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
          )}
        </ul>
      </div> */}
      <div className="icons">
        <a
          href="https://sankalp-s.github.io/"
          className="icon-holder"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>
            <IoLogoChrome />
          </span>
        </a>
        <a
          href="mailto:macrogoogol@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-holder"
        >
          <span>
            <IoMailOutline />
          </span>
        </a>
        <a
          href="https://www.linkedin.com/in/sankalp-arora-10bbbb9a/"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-holder"
        >
          <span>
            <IoLogoLinkedin />
          </span>
        </a>
        <a
          href="https://twitter.com/I_sankalparora"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-holder"
        >
          <span>
            <IoLogoTwitter />
          </span>
        </a>
      </div>
      <div className="divider"></div>
      <p className="copyright">Copyright ©Macrogoogol</p>
    </section>
  );
};

export default Footer;
